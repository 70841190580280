.scrollable-div {
    max-height: 720px; /* set a fixed height for the div */
    max-width: 1000px;
    overflow-y: scroll; /* enable vertical scrollbar */
    display: block;
    padding: 25px;
    border: 2px solid #DDDDDD;
    top: 0 
    
  }

  .button {
    background-color: rgb(255, 255, 255);
    width: 100px;
    font-size: 17px;
    padding: 2px;
    border-radius: 3%;
    color: rgb(0, 0, 0);
    text-align: center;
    display: inline-block;
    margin: 21px 395px;
  }